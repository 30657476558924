// ================================================================================================
//   File Name: pallette.scss
//   Description: Custom color system styles, includes background, border and text colors
//   ----------------------------------------------------------------------------------------------
//   Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

//  ================================================================================================
//  WARNING: PLEASE DO NOT CHANGE THIS VARIABLE FILE.
//  THIS FILE WILL GET OVERWRITTEN WITH EACH Vuexy HTML TEMPLATE RELEASE.
//  TIP:
//  We suggest you to use this (assets/scss/colors/palette.scss) file for overriding color variables.
//  ================================================================================================

// usage: theme-color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function color-function($color, $type) {
	@if map-has-key($colors, $color) {
		$curr_color: map-get($colors, $color);
		@if map-has-key($curr_color, $type) {
			@return map-get($curr_color, $type);
		}
	}

	// @warn "Unknown `#{name}` in $colors.";
	@return null;
}

// Color palettes
@import 'palette-variables';

// Color Classes
//   Text color: .color .lighten-* / .darken-* / .accent-*
//   Background: .bg-color .bg-lighten-* / .bg-darken-* / .bg-accent-*
//   border: .border-color .border-lighten-* / .border-darken-* / .border-accent-*
//   border-top: .border-top-color .border-top-lighten-* / .border-top-darken-* / .border-top-accent-*
//   border-bottom: .border-bottom-color .border-bottom-lighten-* / .border-bottom-darken-* / .border-bottom-accent-*
//   border-left: .border-left-color .border-left-lighten-* / .border-left-darken-* / .border-left-accent-*
//   border-right: .border-right-color .border-right-lighten-* / .border-right-darken-* / .border-right-accent-*

@each $color_name, $color in $colors {
	@each $color_type, $color_value in $color {
		@if $color_type== 'base' {
			// background color
			.bg-#{$color_name} {
				background-color: $color_value !important;

				.card-header,
				.card-footer {
					background-color: transparent;
				}
			}

			// Alert
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.alert-#{$color_name} {
					background: rgba($color_value, 0.12) !important;
					color: $color_value !important;

					.alert-heading {
						@include alert-heading-bs($color_value);
					}

					.alert-link {
						color: darken($color_value, 5%) !important;
					}

					// closable alert
					.close {
						color: $color_value !important;
					}
				}
			}

			// bg color lighten for rgba - opacity set
			@if (
				$color_name !=
					'light' and
					$color_name !=
					'black' and
					$color_name !=
					'white' and
					$color_name !=
					'dark'
			) {
				.bg-light-#{$color_name} {
					background: rgba($color_value, 0.12) !important;
					color: $color_value !important;

					// Calendar background, color and border color
					&.fc-h-event,
					&.fc-v-event {
						border-color: rgba($color_value, 0.1);
					}

					.fc-list-event-dot,
					.fc-daygrid-event-dot {
						border-color: $color_value !important;
					}

					&.fc-list-event {
						&:hover td {
							background: rgba($color_value, 0.1) !important;
						}

						.fc-list-event-title {
							color: $body-color;
						}
					}
				}
			}

			// avatar with light bg
			@if (
				$color_name !=
					'light' and
					$color_name !=
					'black' and
					$color_name !=
					'white' and
					$color_name !=
					'dark'
			) {
				.avatar.bg-light-#{$color_name} {
					color: $color_value !important;
				}
			}

			// Border colors
			.border-#{$color_name} {
				border: 1px solid $color_value !important;
			}

			.border-top-#{$color_name} {
				border-top: 1px solid $color_value;
			}

			.border-bottom-#{$color_name} {
				border-bottom: 1px solid $color_value;
			}

			.border-left-#{$color_name} {
				border-left: 1px solid $color_value;
			}

			.border-right-#{$color_name} {
				border-right: 1px solid $color_value;
			}

			// badge glow colors
			.bg-#{$color_name},
			.border-#{$color_name},
			.badge-#{$color-name} {
				&.badge-glow {
					box-shadow: 0px 0px 10px $color_value;
				}
			}

			// Badge Background Color
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.badge {
					// Badge Light Background
					&.badge-light-#{$color_name} {
						background-color: rgba($color: $color_value, $alpha: 0.12);
						color: $color_value !important;
					}
				}
			}

			// Overlay colors
			.overlay-#{$color_name} {
				@include bg-opacity($color_value, 0.6);
			}

			// Basic buttons
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.btn-#{$color_name} {
					border-color: $color_value !important;
					background-color: $color_value !important;
					color: $white !important;

					&:focus,
					&:active,
					&.active {
						color: $white;
						background-color: color-function($color_name, 'darken-1') !important;
					}

					&:hover:not(.disabled):not(:disabled) {
						box-shadow: 0 8px 25px -8px rgba($color-value, 1);
					}

					&:not(:disabled):not(.disabled):active:focus {
						box-shadow: none;
					}
				}
			}

			// For Btn Flat
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.btn-flat-#{$color-name} {
					background-color: transparent;
					color: $color-value;

					&:hover {
						color: $color-value;
					}

					&:hover:not(.disabled):not(:disabled) {
						background-color: rgba($color-value, 0.12);
					}

					&:active,
					&.active,
					&:focus {
						background-color: rgba($color: $color_value, $alpha: 0.2);
						color: $color_value;
					}

					&.dropdown-toggle::after {
						background-image: url(str-replace(
							str-replace($chevron-down, 'currentColor', $color_value),
							'#',
							'%23'
						));
					}
				}
			}

			// For Btn Relief
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.btn-relief-#{$color-name} {
					background-color: $color-value;
					box-shadow: inset 0 -3px 0 0 rgba($black, 0.2);
					color: $white;
					transition: all 0.2s ease;
					@if $color_name == 'dark' {
						&:hover:not(.disabled):not(:disabled) {
							background-color: color-function($color_name, 'darken-3');
						}
					} @else {
						&:hover:not(.disabled):not(:disabled) {
							background-color: color-function($color_name, 'lighten-1');
						}
					}
					&:active,
					&.active,
					&:focus {
						background-color: color-function($color_name, 'darken-1');
					}

					&:hover {
						color: $white;
					}
					&:active,
					&.active {
						outline: none;
						box-shadow: none;
						transform: translateY(3px);
					}
				}
			}

			// Outline buttons
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.btn-outline-#{$color_name} {
					border: 1px solid $color_value !important;
					background-color: transparent;
					color: $color_value;

					&:hover:not(.disabled):not(:disabled) {
						background-color: rgba($color: $color_value, $alpha: 0.04);
						color: $color_value;
					}
					&:not(:disabled):not(.disabled):active:focus {
						box-shadow: none;
					}
					&:not(:disabled):not(.disabled):active,
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):focus {
						background-color: rgba($color: $color_value, $alpha: 0.2);
						color: $color_value;
					}

					&.dropdown-toggle::after {
						background-image: url(str-replace(
							str-replace($chevron-down, 'currentColor', $color_value),
							'#',
							'%23'
						));
					}
					// split toggle have show class
					.show > &.dropdown-toggle {
						background-color: rgba($color: $color_value, $alpha: 0.2);
						color: $color_value;
					}
				}
			}

			// Wave effect
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.btn-outline-#{$color_name},
				.btn-flat-#{$color-name} {
					&.waves-effect {
						.waves-ripple {
							background: radial-gradient(
								rgba($color_value, 0.2) 0,
								rgba($color_value, 0.3) 40%,
								rgba($color_value, 0.4) 50%,
								rgba($color_value, 0.5) 60%,
								rgba($white, 0) 70%
							);
						}
					}
				}
			}

			// Bullet colors for email app
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.bullet {
					&.bullet-#{$color_name} {
						background-color: $color_value;
					}
				}
			}

			// Modal
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.modal {
					&.modal-#{$color_name} {
						.modal-header {
							.modal-title {
								color: $color_value;
							}
							.close {
								color: $color_value !important;
							}
						}
					}
				}
			}

			// For Pagination
			@if (
				$color_name !=
					'light' and
					$color_name !=
					'black' and
					$color_name !=
					'white' and
					$color_name !=
					'dark'
			) {
				.pagination-#{$color-name} {
					.page-item {
						&.active {
							.page-link {
								background: $color-value !important;
								color: $white;

								&:hover {
									color: $white;
								}
							}
						}

						.page-link {
							&:hover {
								color: $color_value;
							}
						}

						&.prev-item,
						&.next-item {
							.page-link:hover {
								background: $color_value;
								color: $white;
							}
						}
						&.next-item,
						&.next {
							.page-link {
								&:active,
								&:hover {
									&:after {
										background-image: url(str-replace(
											str-replace($chevron-right, 'currentColor', $color_value),
											'#',
											'%23'
										)) !important;
									}
								}
							}
						}
						&.prev-item,
						&.prev {
							.page-link {
								&:active,
								&:hover {
									&:before {
										background-image: url(str-replace(
											str-replace($chevron-left, 'currentColor', $color_value),
											'#',
											'%23'
										)) !important;
									}
								}
							}
						}
					}
				}
			}

			// For Nav Pills
			@if (
				$color_name !=
					'light' and
					$color_name !=
					'black' and
					$color_name !=
					'white' and
					$color_name !=
					'dark'
			) {
				.nav-pill-#{$color-name} {
					.nav-item {
						.nav-link {
							&.active {
								color: $white;
								background-color: $color_value !important;
								border-color: $color_value;
								box-shadow: 0 4px 18px -4px rgba($color_value, 0.65);
							}
						}
					}
				}
			}

			// Progress Bars
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.progress-bar-#{$color_name} {
					background-color: rgba($color_value, 0.12);

					.progress-bar {
						background-color: $color-value;
					}
				}
			}

			// Timeline
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.timeline {
					.timeline-point-#{$color_name} {
						border-color: $color_value !important;

						i,
						svg {
							stroke: $color_value !important;
						}

						&.timeline-point-indicator {
							background-color: $color_value !important;
							&:before {
								background: rgba($color: $color_value, $alpha: 0.12) !important;
							}
						}
					}
				}
			}

			// Divider
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.divider.divider-#{$color-name} {
					.divider-text {
						&:before,
						&:after {
							border-color: $color-value !important;
						}
					}
				}
			}

			// Form Input Elements
			// Checkbox & Radio

			// todo: remove it once confirm
			input:focus ~ .bg-#{$color_name} {
				box-shadow: 0 0 0 0.075rem $white, 0 0 0 0.21rem $color_value !important;
			}

			// Custom Checkbox & Radio
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.custom-control-#{$color_name} {
					.custom-control-input:checked ~ .custom-control-label::before,
					.custom-control-input:active ~ .custom-control-label::before {
						border-color: $color_value;
						background-color: $color_value;
					}
					&.custom-checkbox,
					&.custom-radio {
						.custom-control-input:checked ~ .custom-control-label::before,
						.custom-control-input:active ~ .custom-control-label::before,
						.custom-control-input:focus ~ .custom-control-label::before {
							box-shadow: 0 2px 4px 0 rgba($color_value, 0.4) !important;
						}
					}
					.custom-control-input:disabled:checked ~ .custom-control-label::before {
						background-color: rgba($color_value, 0.65) !important;
						border: none;
						box-shadow: none !important;
					}
					.custom-control-input:focus ~ .custom-control-label::before {
						border-color: $color_value !important;
					}
				}
			}

			// Bootstrap Custom Switches
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.custom-switch-#{$color-name} {
					.custom-control-input:checked ~ .custom-control-label::before {
						background-color: $color_value !important;
						color: $white;
						transition: all 0.2s ease-out;
					}
				}
			}

			// Select2
			@if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
				.select2-#{$color-name} {
					.select2-container--default {
						.select2-selection--multiple {
							.select2-selection__choice {
								background: $color_value !important;
								border-color: $color_value !important;
							}
						}
					}
				}
			}
		} @else {
			.text-#{$color_name}.text-#{$color_type} {
				color: $color_value !important;
			}

			.bg-#{$color_name}.bg-#{$color_type} {
				background-color: $color_value !important;
			}

			.border-#{$color_name}.border-#{$color_type} {
				border: 1px solid $color_value !important;
			}

			.border-top-#{$color_name}.border-top-#{$color_type} {
				border-top: 1px solid $color_value !important;
			}

			.border-bottom-#{$color_name}.border-bottom-#{$color_type} {
				border-bottom: 1px solid $color_value !important;
			}

			.border-left-#{$color_name}.border-left-#{$color_type} {
				border-left: 1px solid $color_value !important;
			}

			.border-right-#{$color_name}.border-right-#{$color_type} {
				border-right: 1px solid $color_value !important;
			}

			.overlay-#{$color_name}.overlay-#{$color_type} {
				@include bg-opacity($color_value, 0.6);
			}
		}
	}
}
