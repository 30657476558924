/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.sidebar-group-active {
	display: flex;
	align-items: center;
}

.main-menu-content {
	display: flex;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	height: 0 !important;
}

.datatalbe_bg_color .rdt_TableRow:nth-child(even) {
	background-color: #fafafa;
}
#main-menu-navigation .nav-item .nav-link {
	margin: -10px 0 -10px -14px;
}

#main-menu-navigation .nav-item {
	padding: 8px 20px 8px 0 !important;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .nav-link {
	padding: 5px 5px !important;
}

.feed_select .select__control {
	height: 29.99px !important;
	min-height: 29.99px !important;
	align-content: center;
}

.filter_button,
.seed_button {
	padding: 7px 10px;
}

.doBktq {
	min-height: auto !important;
}

.deskboard_card {
	margin: 0 !important;
}

.deskboard_card_body {
	padding: 8px 15px;
}

.dropdown {
	display: flex;
}

.footer-static {
	padding: 0 2rem !important;
}

.navbar-nav {
	align-items: center;
	padding: 0 !important;
}

footer.footer {
	min-height: 2rem;
}

.navbar-static .app-content {
	padding: 10px 15px 0 15px !important;
}

.rdt_Pagination {
	min-height: 0px !important;
}

.navbar-brand span {
	font-size: 20px;
}

.navbar-brand {
	font-size: 24px;
}

.footer.footer-light.footer-static {
	padding: 0 14px !important;
}

.footer.footer-light.footer-static p {
	line-height: 1.45;
	margin-top: 10px;
}

.app-user-list .card {
	margin-bottom: 0;
}

#main-menu-navigation .nav-item:first-child .nav-link span {
	font-size: 20px;
	align-self: end;
}

.invoice-list-table-header .btn {
	padding: 7px 20px;
	line-height: 1.2;
	margin-bottom: 0;
}

.react-dataTable header {
	min-height: 8px;
}

.header_text_size {
	font-size: 15px;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar {
	min-height: 2.45rem !important;
}

[dir] .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	padding: 0.77rem 1.5rem !important;
}

.horizontal-layout.navbar-static .content {
	min-height: calc(100% - calc(4.45rem + calc(3.35rem + 3.15rem))) !important;
}

.label_width {
	width: 70%;
}

.stone_image_container img {
	width: 230px;
	height: 160px;
	margin: 10px auto;
	display: block;
	object-fit: cover;
	max-width: 100%;
}

.upload_btn {
	margin: 0 auto;
	display: block;
}

.dGqwdB {
	border: 0 !important;
}

.product_search_button {
	width: 27% !important;
}

.rdt_TableRow {
	position: relative;
}

// .gIMaKV{
// 	// overflow: scroll;
// }

.table_height .gIMaKV {
	height: 200px !important;
}
.table_height .iazphd {
	height: 200px !important;
}

.gIMaKV .gAaLCe {
	position: sticky !important;
	left: 0 !important;
}

.model_body {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

// .modal-content{
// 	height: 600px !important;
// }
.lg-img-wrap,
.lg-video-loaded {
	background-color: #000 !important;
}

.lg-content {
	top: 7px !important;
}

.lg-thumb-outer {
	background-color: #000 !important;
}

.gallary_disabled,
.trash_disabled {
	pointer-events: none;
	opacity: 0.4;
}

//--- model ---///

.modal-content {
	width: 100vw;
}
.card-container {
	border: 3px solid #e1e1e1;
	border-radius: 7px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
	width: 250px;
}

.card-container .card {
	margin-bottom: 0px !important;
}

.cardImg img {
	height: 120px;
	width: auto;
	margin-top: 10px;
}

.cardImg .card-body {
	padding: 0px;
}
.cardImg video {
	height: 120px;
	width: 210px;
	margin-top: 10px;
}
.card-body-content {
	padding: 10px;
}
.item-name {
	height: 25px !important;
	padding: 5px !important;
}

.remove-btn-image {
	padding: 7px 8px;
}

.product-add-img-content {
	color: white;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 14px;
}
